import React from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { useSelector } from "react-redux";

import { PageProps, TranslateString } from "../types";
import PageHeader from "../components/PageHeader";
import IconTitleText from "../components/IconTitleText";
import { ReactComponent as IconGift } from "../assets/images/icon-gift.svg";
// import { ReactComponent as IconPercentage } from "../assets/images/icon-percentage.svg";
// import { ReactComponent as IconCalendar } from "../assets/images/icon-calendar.svg";
import { ReactComponent as IconLock } from "../assets/images/icon-lock.svg";
import { ReactComponent as IconSendCheck } from "../assets/images/icon-send-check.svg";
import { useProfile } from "../features/user/use-profile";
import EmailForm from "../features/bonus/EmailForm";
import Spinner from "../components/Spinner";
import Button from "../components/Button";
import { navigateTo } from "../utils/navigation";
import { selectUserLocale } from "../ducks/user";

import fiBenefitImageSrc from "../assets/images/bonus/fi/MROOM_Q3_ETU.png";

const LOCALE_IMAGES = {
  FI: fiBenefitImageSrc,
} as Record<string, string>;

interface BonusTeaserProps extends PageProps {}

const BonusTeaser: React.FC<BonusTeaserProps> = ({ translate }) => {
  const { profile, refresh } = useProfile();
  const { email, allow_email } = profile || {};

  const hasProfile = typeof profile !== "undefined";
  const hasEmail = !!email;
  const hasGivenMarketingPermission = allow_email === true;
  const isComplete = hasProfile && hasEmail && hasGivenMarketingPermission;

  const locale = useSelector(selectUserLocale);

  const benefitImage =
    typeof locale === "string" && locale in LOCALE_IMAGES
      ? LOCALE_IMAGES[locale]
      : undefined;
  const hasActiveBenefit = !!benefitImage;

  return (
    <div className="s-bonus-teaser h-scrollable">
      <div className="s-bonus-teaser__wrapper">
        <PageHeader title={<Translate id="bonusTeaser.pageHeader" />} />

        {hasActiveBenefit ? (
          <Benefit
            image={LOCALE_IMAGES[locale]}
            alt={translate("bonusTeaser.activeBenefit.imageAlt").toString()}
            title={translate("bonusTeaser.activeBenefit.title").toString()}
            display={translate("bonusTeaser.activeBenefit.display").toString()}
            subtitle={translate(
              "bonusTeaser.activeBenefit.subtitle"
            ).toString()}
            footerLeft={translate(
              "bonusTeaser.activeBenefit.footerLeft"
            ).toString()}
            footerRight={translate(
              "bonusTeaser.activeBenefit.footerRight"
            ).toString()}
            disabled={!isComplete}
          >
            <Form
              isLoading={!hasProfile}
              disabled={isComplete}
              initialEmail={hasEmail ? email : ""}
              onComplete={() => refresh()}
            />
          </Benefit>
        ) : (
          <Form
            isLoading={!hasProfile}
            disabled={isComplete}
            initialEmail={hasEmail ? email : ""}
            onComplete={() => refresh()}
          />
        )}

        <div className="s-bonus-teaser__header">
          <h2 className="s-bonus-teaser__title">
            <Translate id="bonusTeaser.title" />
          </h2>

          {isComplete && (
            <div className="s-bonus-teaser__success">
              <h4 className="s-bonus-teaser__subtitle">
                <Translate id="bonusTeaser.success.title" />
              </h4>

              <Button
                text={<Translate id="emailSettings.subtitle" />}
                size="small"
                onClick={() => navigateTo("/profile/email", "switch-open")}
              />
            </div>
          )}
        </div>

        <div className="s-bonus-teaser__content">
          {/* <IconTitleText
            icon={<IconPercentage />}
            title={<Translate id="bonusTeaser.visits.title" />}
            text={<Translate id="bonusTeaser.visits.text" />}
          /> */}

          <IconTitleText
            icon={<IconGift />}
            title={<Translate id="bonusTeaser.benefits.title" />}
            text={<Translate id="bonusTeaser.benefits.text" />}
          />

          <IconTitleText
            icon={<IconSendCheck />}
            title={<Translate id="bonusTeaser.terms.title" />}
            text={<Translate id="bonusTeaser.terms.text" />}
          />
        </div>
      </div>
    </div>
  );
};

const Form = ({
  isLoading,
  disabled,
  initialEmail,
  onComplete,
}: {
  isLoading: boolean;
  disabled: boolean;
  initialEmail: string;
  onComplete: () => void;
}) => {
  if (isLoading) {
    return (
      <div className="s-bonus-teaser__form">
        <Spinner />
      </div>
    );
  }

  if (disabled) {
    return null;
  }

  return (
    <div className="s-bonus-teaser__form">
      <div className="s-bonus-teaser__needs-email">
        <div className="s-bonus-teaser__form-icon">
          <IconLock />
        </div>
        <h2 className="s-bonus-teaser__form-title">
          <Translate id="bonusTeaser.needsEmail.title" />
        </h2>

        <div className="s-bonus-teaser__form-inner">
          <EmailForm
            initialEmail={initialEmail}
            label={<Translate id="bonusTeaser.needsEmail.label" />}
            cta={<Translate id="bonusTeaser.needsEmail.cta" />}
            marketingPermissionLabel={
              <Translate id="bonusTeaser.needsEmail.marketingLabel" />
            }
            requireMarketingPermission={true}
            emailErrorText={
              <Translate id="bonusTeaser.needsEmail.emailError" />
            }
            onComplete={onComplete}
          />
        </div>
      </div>
    </div>
  );
};

const Benefit = ({
  image,
  alt,
  title,
  display,
  subtitle,
  footerLeft,
  footerRight,
  disabled,
  children,
}: {
  image: string;
  alt: string;
  title?: TranslateString;
  display?: TranslateString;
  subtitle?: TranslateString;
  footerLeft?: TranslateString;
  footerRight?: TranslateString;
  disabled: boolean;
  children: React.ReactNode;
}) => {
  const componentClass = ["s-bonus-teaser__benefit"];

  if (disabled) {
    componentClass.push("is-disabled");
  }

  return (
    <div className={componentClass.join(" ")}>
      {!!image && (
        <div className="s-bonus-teaser__benefit-image">
          <img src={image} alt={alt} />
        </div>
      )}
      {isNotTranslateError(title) && (
        <h3 className="s-bonus-teaser__benefit-title">{title}</h3>
      )}
      {isNotTranslateError(display) && (
        <h4 className="s-bonus-teaser__benefit-display">{display}</h4>
      )}
      {isNotTranslateError(subtitle) && (
        <h5 className="s-bonus-teaser__benefit-subtitle">{subtitle}</h5>
      )}
      <div className="s-bonus-teaser__benefit-footer">
        <h6>{isNotTranslateError(footerLeft) ? footerLeft : ""}</h6>
        <h6>{isNotTranslateError(footerRight) ? footerRight : ""}</h6>
      </div>

      <div className="s-bonus-teaser__benefit-content">{children}</div>
    </div>
  );
};

const isNotTranslateError = (str?: TranslateString) => {
  return typeof str === "string" && !str.startsWith("Missing translationId");
};

export default withLocalize(BonusTeaser);
